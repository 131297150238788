import React, { Component } from 'react'
import Select from 'react-select'


class RenderSelectMulti  extends Component {
	renderOption(option) {
		return(<span>{option.label}<i className="fa fa-info-circle" style={{'marginLeft': "5px","fontSize":"16"}} data-toggle="tooltip" data-placement="right" title={option.description}></i></span>)
	}

	render() {
		const {
			input,
			label,
			placeholder,
			options,
			disabled,
			className,
		} = this.props

		return(
			<div>
			  <label>{label}</label>
		  	<Select
				className={className}
				value={input.value}
				onChange={input.onChange}
				onBlur={() => input.onBlur(input.value)}
				options={options}
				placeholder={placeholder}
				clearable={false}
				disabled={disabled}
				multi={true}
				removeSelected={true}
				simpleValue 
				optionRenderer={this.renderOption}
			/>
			</div>
		)
	}
}

export default RenderSelectMulti
