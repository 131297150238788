import React, { Component } from 'react'

class RenderInputPercent  extends Component {
	render() {
		const { input, id, type, disabled, label, placeholder, desc, meta: { touched, error }, className} = this.props

		return(
				<div className={`input-container ${touched && error && 'has-error'}`}>
					<div><label>{label}</label></div>
					<div className="input-group">
						<input className={className} type={type} {...input} placeholder={placeholder} id={id} disabled={disabled} aria-describedby="addon" step={'0.01'}></input>
						<span class="input-group-addon" id="addon">%</span>
					</div>
					{touched && error && <div><span className="error">{error}</span></div>}
				</div>
				
		)
	}
}


export default RenderInputPercent
