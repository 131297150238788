import { List, Map, fromJS } from 'immutable'

const initFilterData = fromJS({
	page: '',
	selected_ids: [],
	is_update: false,
	sort: '',
	fullname: '',
	job_id:'',
	per_page:10,
	is_active:'',
	from:'',
	to:'',
	company_id:'',
	user_level:'',
	search:''
})

export function vouchers(state = List([]), action) {
	switch(action.type) {
		case 'VOUCHERS_FETCH_DATA_SUCCESS':
			return fromJS(action.vouchers)
		default:
			return state
	}
}

export function vouchersIds(state = List([]), action) {
  switch(action.type) {
    case 'VOUCHERS_FETCH_IDS_SUCCESS':
    	return List(action.ids)
    default:
    	return state
  }
}


export function vouchersPagination(state = Map({}), action) {
	switch(action.type) {
		case 'VOUCHERS_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}

export function vouchersFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'VOUCHERS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'VOUCHERS_ADD_FILTER_SELECTED_IDS_SUCCESS':
			return state.set('selected_ids', List(action.selected_ids))
		case 'VOUCHERS_ADD_FILTER_IS_UPDATE_SUCCESS':
			return state.set('is_update', action.is_update)
		case 'VOUCHERS_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'VOUCHERS_ADD_FILTER_FULLNAME_SUCCESS':
			return state.set('fullname', action.fullname)
		case 'VOUCHERS_ADD_FILTER_JOB_ID_SUCCESS':
			return state.set('job_id', action.job_id)
		case 'VOUCHERS_ADD_FILTER_PER_PAGE_NUMBER_SUCCESS':
			return state.set('per_page',action.per_page)
		case 'VOUCHERS_ADD_FILTER_IS_ACTIVE_SUCCESS':
			return state.set('is_active',action.is_active)
		case 'VOUCHERS_ADD_FILTER_LOGIN_FROM_SUCCESS':
			return state.set('from',action.from)
		case 'VOUCHERS_ADD_FILTER_LOGIN_TO_SUCCESS':
			return state.set('to',action.to)
		case 'VOUCHERS_ADD_FILTER_COMPANY_ID_SUCCESS':
			return state.set('company_id',action.company_id)
		case 'VOUCHERS_ADD_FILTER_USER_LEVEL_SUCCESS':
			return state.set('user_level',action.user_level)
		case 'VOUCHERS_ADD_FILTER_SEARCH_SUCCESS':
			return state.set('search', action.search)
		case 'VOUCHERS_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				selected_ids: [],
				is_update: false,
				sort: '',
				fullname: '',
				job_id:'',
				per_page:10,
				is_active:'',
				from:'',
				to:'',
				company_id:'',
				user_level:'',
				search:''
			})
		default:
			return state
	}
}


export function voucher(state = Map({}), action) {
	switch(action.type) {
		case 'VOUCHER_GET_DETAIL_SUCCESS':
			return fromJS(action.voucher)
		case 'CLEAR_FORM_DATA_VOUCHER_SUCCESS':
			return fromJS({
				code: '',
				description: '',    
				platform: '',       
				discount_type: '',  
				currency: '',       
				discount_value: '', 
				start_date: '',     
				end_date: '',       
				max_user: '',       
				product: '',        
				product_values: '', 
				category: '',       
				category_values: ''
			})
		default:
			return state
	}
}


export function certificates(state = List([]), action) {
	switch(action.type) {
		case 'VOUCHER_GET_CERTIFICATES_SUCCESS':
			return fromJS(action.certificates)
		default:
			return state
	}
}

