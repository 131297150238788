import isNil from 'lodash/isNil'

import isEmpty from 'lodash/isEmpty'
import configurations from '../../misc/configurations'

const validate = values => {
	const errors = {}
	if (!values.code) {
		errors.code = `Voucher Code can’t be blank`
	}

	if (!values.description) {
		errors.description = `Description can’t be blank`
	}


	if (!values.platform) {
		errors.platform = `Please select platform`
	}

	if (!values.product) {
		errors.product = `Applicable Product can’t be blank`
	}

	if(values.platform=='popskul'){
		if (!values.product_value) {
			errors.product_value = `Please select product ${values.product}`
		}
	}

	if (!values.discount_type) {
		errors.discount_type = `Please select discount type`
	}

	if (!values.discount_value) {
		errors.discount_value = `Discount Value can’t be blank`
	}else{
		if (values.discount_value <= 0) {
			errors.discount_value = `Discount can't be 0`
		}

		if(values.discount_type=='percent' && values.discount_value > 100){
			errors.discount_value = `Discount can't greater than 100`
		}
	}

	if (!values.discount_type!='percent') {
		if (!values.currency) {
			errors.currency = `Please select currency`
		}
	}

	if(values.platform=='astrnt'){
		if (!values.category) {
			errors.category = `Please select category`
		}

		if (!values.category_value) {
			errors.category_value = `${values.category} can't be blank`
		}
	}
	
	if (!values.valid_from) {
		errors.valid_from = `Valid From Date can’t be blank`
	}

	if (!values.valid_to) {
		errors.valid_to = `Valid To Date can’t be blank`
	}

	if (!values.max_user) {
		errors.max_user = `Maximum Redemption can’t be blank`
	}

	return errors
}

export default validate

