// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import $ from 'jquery'
import { Map, List } from 'immutable'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'
import moment from 'moment'

import {
	usersAddFilterIsActive,
	usersAddFilterCompanyId,
	usersAddFilterUserLevel,
	usersAddFilterLoginFrom,
	usersAddFilterLoginTo,
	getDropdownUserLevel,
	usersFetchData,
	usersAddFilterPageNumber
} from '../../actions/users'

import {
	globalAddSweetAlertData,
} from '../../actions/global'

import {
	getDropdownCompanies,
	companiesGetDetail
} from '../../actions/companies'

type Props = {
	usersAddFilterIsActive: Function,
	usersAddFilterCompanyId: Function,
	usersAddFilterUserLevel: Function,
	usersAddFilterLoginFrom: Function,
	usersAddFilterLoginTo: Function,
	getDropdownUserLevel: Function,
	usersFetchData: Function,
	getDropdownCompanies: Function,
	companiesGetDetail: Function,
	usersFilterData: Map<string, any>,
	usersAddFilterPageNumber: Function,
}

class ModalAdvancedFilter extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			value: [],
			valueCompanies: [],
			fromDate: '',
			toDate: '',
			isActive:1,
			selectedCompany:''
		}

		bindAll(this, [
			'handleCloseModalClick',
			'handleSelectChange',
			'handleSelectCompany',
			'handleSelectUserLevel',
			'handleSelectStatus',
			'handleFromDateChange',
			'handleToDateChange',
			'handleSubmitFilter',
		])
	}

	componentDidMount() {
		this.props.getDropdownUserLevel()
		this.props.getDropdownCompanies({label_id: true})
	}

	handleCloseModalClick(e: Object) {
		e.preventDefault()

		$('#modalAdvancedFilter').modal('hide')
	}

	handleSelectChange (value) {
		this.setState({ value });
	}

	handleFromDateChange(value) {
		let date = value.format('YYYY-MM-DD')
		this.setState({
			fromDate: value
		});
		this.props.usersAddFilterLoginFrom(date)
  }

  handleToDateChange(value) {
		let date = value.format('YYYY-MM-DD')
		this.setState({
			toDate: value
		});
		this.props.usersAddFilterLoginTo(date)
  }


	handleSelectCompany(e) {
		const {
			usersAddFilterCompanyId
		} = this.props
		let companyId=e
		this.setState({valueCompnay:companyId, selectedCompany: companyId})
		usersAddFilterCompanyId(companyId)
	}

	handleSelectUserLevel(value) {
		this.setState({ value });
		this.props.usersAddFilterUserLevel(value)
	}

	handleSelectStatus(e, value) {
		const {
			usersAddFilterIsActive
		} = this.props
		this.setState({ isActive: value });
		usersAddFilterIsActive(value)
	}

	handleSubmitFilter(e){
		e.preventDefault()
		const {
			usersFetchData,
			usersAddFilterPageNumber,
			usersAddFilterIsActive
		} = this.props
		
		if(this.props.usersFilterData.get('is_active')==''){
			usersAddFilterIsActive(this.state.isActive)
			.then(()=>usersAddFilterPageNumber(1)
			.then(()=>usersFetchData(this.props.usersFilterData)))
		}else{
			usersAddFilterPageNumber(1).then(()=>usersFetchData(this.props.usersFilterData))
		}
		
		this.handleCloseModalClick(e)
	}

  	render() {
		const {
			dropdownUserLevels,
			companiesDropdown,
			usersFilterData
		} = this.props

  		const { value, valueCompanies, selectedCompany } = this.state;
  		const options = dropdownUserLevels.toJS()
		  
    	return (
      		<div className="modal custom-modal" id="modalAdvancedFilter" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        		<div className="modal-dialog wrapper-modal-advanced-filter">
          			<div className="modal-content animated bounceInRight">
            			<div className="modal-header">
            				<label>Advanced Filter</label>
            			</div>
						<div className="modal-body">
							<div className="input-container">
								<label>Company ID</label>
								<Select
									closeOnSelect={true}
									options={companiesDropdown.toJS()}
									onChange={this.handleSelectCompany}
									placeholder="Select Company"
				          			removeSelected={false}
									simpleValue
									value={usersFilterData.get('company_id')}
								/>
								{/* <input className="input-company" value={this.state.selectedCompany} placeholder="Enter Company ID" onChange={this.handleSelectCompany}/> */}
							</div>
							<div className="input-container">
								<label>User Level</label>
								<Select
									closeOnSelect={false}
									multi
									options={options}
									onChange={this.handleSelectUserLevel}
									placeholder="Select user level"
				          			removeSelected={false}
									simpleValue
									value={usersFilterData.get('user_level')}
								/>
							</div>
							<div className="input-container">
								<label>Status</label>
								<div className="toggle-container">
									<button className={`btn-status-toggle ${usersFilterData.get('is_active') === 1 || usersFilterData.get('is_active') === '' ? 'on':'off'}`} onClick={(e)=>this.handleSelectStatus(e,1)}>Active</button>
									<button className={`btn-status-toggle ${usersFilterData.get('is_active') === 0 ? 'on':'off'}`} onClick={(e)=>this.handleSelectStatus(e,0)}>Inactive</button>
								</div>
							</div>
							<div className="input-container">
								<label>Last Logged in</label>
								<div className="datepicker-container horizontal-item--between">
									<div className="vertical-item--center">
										<span>From</span>
										<DatePicker
											dateFormat="DD/MM/YYYY"
											selected={usersFilterData.get('from') == '' ? null : moment(usersFilterData.get('from'))}
											onChange={this.handleFromDateChange}
											placeholderText="Select From Date"
											maxDate={usersFilterData.get('to') ==''? moment() : moment(usersFilterData.get('to'))}
											showDisabledMonthNavigation/>
									</div>
									<div className="vertical-item--center">
										<span>To</span>
										<DatePicker
											dateFormat="DD/MM/YYYY"
											selected={usersFilterData.get('to') == '' ? null : moment(usersFilterData.get('to'))}
											onChange={this.handleToDateChange}
											placeholderText="Select To Date" 
											minDate={moment(usersFilterData.get('from'))}
											maxDate={moment()}
											showDisabledMonthNavigation/>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button className="button button-cancel m-r-16" onClick={this.handleCloseModalClick}>Cancel</button>
							<button className="button button-apply" onClick={(e)=>this.handleSubmitFilter(e)}>Apply</button>
						</div>
          			</div>
        		</div>
      		</div>
    	)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		dropdownUserLevels: state.dropdownUserLevels,
		usersFilterData: state.usersFilterData,
		companiesDropdown: state.companiesDropdown,
		company: state.company
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		usersFetchData: (user_filter) => dispatch(usersFetchData(user_filter)),
		usersAddFilterIsActive: (is_active) => dispatch(usersAddFilterIsActive(is_active)),
		usersAddFilterCompanyId: (company_id) => dispatch(usersAddFilterCompanyId(company_id)),
		usersAddFilterUserLevel: (user_level) => dispatch(usersAddFilterUserLevel(user_level)),
		usersAddFilterLoginFrom: (from) => dispatch(usersAddFilterLoginFrom(from)),
		usersAddFilterLoginTo: (to) => dispatch(usersAddFilterLoginTo(to)),
		getDropdownUserLevel: () => dispatch(getDropdownUserLevel()),
		getDropdownCompanies: (params) => dispatch(getDropdownCompanies(params)),
		companiesGetDetail: (companyId) => dispatch(companiesGetDetail(companyId)),
		usersAddFilterPageNumber: (page) => dispatch(usersAddFilterPageNumber(page))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdvancedFilter)
