// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import { Map, fromJS } from 'immutable'
import { Field, reduxForm, change, formValueSelector } from 'redux-form'
import validate from './validate'
import RenderInput from '../partials/RenderInput'
import RenderSelect from '../partials/RenderSelect'
import RenderTextArea from '../partials/RenderTextArea'
import RenderInputEmail from '../partials/RenderInputEmail'
import RenderInputNumber from '../partials/RenderInputNumber'
import RadioButton  from '../partials/RadioButton'
import RenderSelectMulti from '../partials/RenderSelectMulti'
import RenderSelectMultiCreatable  from '../partials/RenderSelectMultiCreatable'
import RenderInputCopy  from '../partials/RenderInputCopy'
import RenderInputPercent  from '../partials/RenderInputPercent'

import $ from 'jquery'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
	voucherSaveData,
	voucherUpdateData,
	voucherGetDetail,
	resetFormVoucher,
	voucherCreateData,
	voucherGetCertificates,
	validateVoucherCode
} from '../../actions/vouchers'

import {
	getDropdownFeatures
} from '../../actions/features'

import {
	getDropdownCompanies
} from '../../actions/companies'
import moment from 'moment'
import { certificates } from '../../reducers/vouchers'

require('es6-promise').polyfill()

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	globalIndicatorData: Map<string, any>,
  	changeFieldValue: Function,
	handleSubmit: Function,
	pristine: boolean,
	submitting: boolean,
	voucherSaveData: Function,
	voucherUpdateData: Function,
	voucherGetDetail: Function,
	getDropdownFeatures:Function,
	resetFormVoucher:Function,
	voucherCreateData:Function,
	getDropdownCompanies: Function,
	voucherGetCertificates: Function,
	asyncValidating: boolean,
};

type State = {
	isShown: boolean
};

class VoucherForm extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selectedFeatureValue:[],
			selectedFeatureLabel:[],
			tempSelectedFeature:{}
		}

		bindAll(this, [
			'handleSaveData',
			'handleSelectDiscountType',
			'handleSelectPlatform',
			'handleSelectProduct',
			'handleSelectProductValues',
			'handleSelectCategory',
			'handleSelectCategoryValues',
			'changeValidFrom',
			'changeValidTo',
			'handleSelectCurrency',
			'handleCodeChange'
		])
	}

	componentDidMount() {
		const {
			voucherId,
			voucherGetDetail,
			resetFormVoucher,
			changeFieldValue,
			voucherCreateData,
			voucher
		} = this.props
		if(voucherId!=undefined){
			voucherGetDetail(voucherId).then((res)=>{
				changeFieldValue('code',this.props.voucher.get('code'))
			})
		}else{
			resetFormVoucher()
			voucherCreateData().then((res)=>{
				changeFieldValue('code',this.props.voucher.get('code'))
			})
		}

		this.props.getDropdownCompanies({label_id: true})
		this.props.voucherGetCertificates({})
		$('[data-toggle=tooltip]').tooltip()
	}

	handleSaveData(values) {
		const {
			voucherSaveData,
			voucherUpdateData,
			voucherId
		} = this.props

		if(values.category=='email'){
			let emails=''
			values.category_values.map(email=>{
				emails += `, ${email}`
			})
			values.category_values = emails;
		}

		if(voucherId==undefined){
			voucherSaveData(values)
		}else{
			voucherUpdateData(voucherId,values)
		}

	}

	handleSelectPlatform(e){
		const {
			changeFieldValue
		} = this.props

		changeFieldValue('platform',e.target.value)
	}

	handleSelectDiscountType(e){
		const {
			changeFieldValue
		} = this.props
		changeFieldValue('currency',null)
		changeFieldValue('discount_value',null)
		changeFieldValue('discount_type',e.target.value)
	}

	handleSelectProduct(e){
		const {
			changeFieldValue
		} = this.props

		changeFieldValue('product_values',null)
		changeFieldValue('product',e.target.value)
	}

	handleSelectProductValues(e){
		const {
			changeFieldValue
		} = this.props

		// changeFieldValue('product_values',e.target.value)
	}

	handleSelectCategory(e){
		const {
			changeFieldValue
		} = this.props
		changeFieldValue('category_values',null)
		changeFieldValue('category',e.target.value)
	}

	handleSelectCategoryValues(e){
		const {
			changeFieldValue,
			formValues
		} = this.props

		if(formValues.category=='email'){
		}else{
		}

	}

	changeValidFrom(val) {
		const{changeFieldValue} = this.props
		changeFieldValue('valid_from',val)
	}

	changeValidTo(val) {
		const{changeFieldValue} = this.props
		changeFieldValue('valid_to',val)
	}

	handleSelectCurrency(e){
		const {
			changeFieldValue
		} = this.props

		changeFieldValue('currency',e.target.value)
	}

	renderError({ meta: { touched, error } }) {
		return touched && error ? <div><span className="error">{error}</span></div> : false
	}

	handleCodeChange(e) {
		let code = e.target.value;
		if(code!=this.props.voucher.get('code')){
			if(code.length >=8 ){
				this.props.validateVoucherCode(e.target.value)
			}
		}
	}

  	render() {
		const {
			globalIndicatorData,
			handleSubmit,
    		pristine,
			submitting,
			voucherId,
  			asyncValidating,
			userPrivilege,
			formValues,
			voucher,
			companiesDropdown,
			certificates
		} = this.props

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		let dropdownProduct=[]
		let dropdownCurrency=[
			{value:'idr',label:'IDR'},
			{value:'usd',label:'USD'}
		]
		let categoryDropdown = [
			{value: "Talent Acquisition", label: "Talent Acquisition", description:'Hire top talent efficiently. Make data-driven hiring decisions.'},
			{value: "Talent Management", label: "Talent Management", description:'Develop and retain high-performing employees. Build a strong workforce.'},
			{value: "Small Medium Enterprise", label: "Small Medium Enterprise", description:'Hire the right people to grow your business. Optimize hiring processes.'},
			{value: "University Admission", label: "University Admission", description:'Select qualified students for enrollment. Build a diverse student body.'},
			{value: "Career Development Center", label: "Career Development Center", description:'Prepare students for successful careers. Measure student outcomes.'}
		]

		if(formValues.category=='company'){
			categoryDropdown = companiesDropdown.toJS()
		}

		let categoryTitle = {
			'account-segment': 'Account Segment',
			'company': 'Company ID',
			'email': 'Email',
		}

		if(formValues.platform=='popskul'){
			dropdownProduct = [
				{value:'Competency Certification',label:'Competency Certification'},
				{value:'Courses',label:'Courses'}
			]
		}else{
			dropdownProduct = [
				{value:'Purchase Essential Monthly',label:'Purchase Essential Monthly'},
				{value:'Purchase Essential Yearly',label:'Purchase Essential Yearly'},
				{value:'Purchase Block Credit',label:'Purchase Block Credit'}
			]
		}

		let productValues = []

		if(formValues.product=='Competency Certification'){
			productValues = certificates.toJS();
		}

    	return (
			<div className={isIndicatorClass}>
	      		<div className="div-support-container">
	        		<section className="wrapper">
	          			<div className="main-content">
	            			<div className="container-fluid">
	              				<div className="big-panel">
									<div className="flex-container flex-between">
										<h5 className="title-heading">{voucherId==undefined ? 'Create Voucher':'Edit Voucher'}</h5>
									</div>

	                				<div className="div-support-create-user-container">
	                					<div><Link to={{pathname: '/support/voucher', state: {prevPage: 'form-voucher'}}} className="back-button"><i className="fa fa-angle-left"></i>Back</Link></div>
	                					<hr />
										<form onSubmit={handleSubmit(this.handleSaveData)} id="job-form_create_new_job" encType="multipart/form-data">
											<div>
												<Field
													name="code"
													type="text"
													component={RenderInputCopy}
													label="Voucher Code"
													placeholder="Voucher code"
													id="code"
													onChange={this.handleCodeChange}
													props={{
														isValidatingEmail: asyncValidating
													}}
													infoLabel="Can only contain letters and numbers; 8-16 characters."
													value={formValues.code}
													min={8}
													max={16}
												/>
											</div>
											<div>
												<Field
													name="description"
													component={RenderTextArea}
													label="Description"
													placeholder="Description"
													id="description"
													style={{width:'100%'}}
													rows={2}
													max={180}
												/>
												<span className='pull-right'>{formValues.description ? formValues.description.length:0 }/180</span>
											</div>
											<div>
												<div className='m-t-10 m-b-5'>
													<label>Applicable Platform</label>
												</div>
												<Field
													component={RadioButton}
													type ="radio"
													value="astrnt"
													name="platform"
													className={`m-b-10 channel as-radio m-r-15 ${formValues.platform=="astrnt" ? "is-on":null}`}
													onChange={this.handleSelectPlatform}
												>
													ASTRNT
												</Field>
												<Field
													component={RadioButton}
													type ="radio"
													value="popskul"
													name="platform"
													className={`channel as-radio m-r-15 ${formValues.platform=="popskul" ? "is-on":null}`}
													onChange={this.handleSelectPlatform}
												>
													POPSkul
												</Field>
												<Field name="platform" component={this.renderError}/>
											</div>
											<div className='m-t-10'>
												<Field
													name="product"
													component={RenderSelect}
													label="Applicable Product"
													placeholder="- Select Product -"
													id="product"
													disableOptionText="- Select Product -"
													dropdownOption={fromJS(dropdownProduct)}
													onChange={this.handleSelectProduct}
												/>
											</div>

											{
												(formValues.platform=='popskul' && ['Competency Certification','Courses'].includes(formValues.product)) && 
												<div>
													<Field
														name="product_values"
														component={RenderSelectMulti}
														label={formValues.product}
														placeholder={`- Select ${formValues.product} -`}
														id="product_values"
														options={productValues}
														onChange={this.handleSelectProductValues}
														searchable={true}
														multiple={true}
														defaultValue={voucher.get('product_values')}
													/>
												</div>
											}
											{
												formValues.platform=='astrnt' &&
												<div>
													<div  className='m-t-10 m-b-5'>
														<label>Category</label>
													</div>
													<Field
														component={RadioButton}
														type ="radio"
														value="account-segment"
														name="category"
														className={`channel as-radio m-r-15 ${formValues.category=="account-segment" ? "is-on":null}`}
														onChange={this.handleSelectCategory}
													>
														By Account Segment
													</Field>
													<Field
														component={RadioButton}
														type ="radio"
														value="company"
														name="category"
														className={`channel as-radio m-r-15 ${formValues.category=="company" ? "is-on":null}`}
														onChange={this.handleSelectCategory}
													>
														By Company Id
													</Field>
													<Field
														component={RadioButton}
														type ="radio"
														value="email"
														name="category"
														className={`channel as-radio m-r-15 ${formValues.category=="email" ? "is-on":null}`}
														onChange={this.handleSelectCategory}
													>
														By Email
													</Field>
													<Field name="category" component={this.renderError}/>
												</div>
											}
											{
												(formValues.category!='' && ['account-segment','company'].includes(formValues.category)) && 
												<div  className='m-t-10 m-b-5'>
													<Field
														name="category_values"
														component={RenderSelectMulti}
														label={categoryTitle[formValues.category]}
														placeholder={`- Select ${categoryTitle[formValues.category]} -`}
														id="category_values"
														options={categoryDropdown}
														onChange={this.handleSelectCategoryValues}
														searchable={true}
														multiple={true}
														defaultValue={voucher.get('category_values')}
													/>
												</div>
											}

											{
												(formValues.category!='' && formValues.category=='email') && 
												<div>
													<Field
														name="category_values"
														component={RenderSelectMultiCreatable}
														placeholder={`Email`}
														label="Email"
														defaultValue={formValues.category_values}
														onChange={this.handleSelectCategoryValues}
													/>
												</div>
											}
											<div>
												<div  className='m-t-10 m-b-5'>
													<label>Discount Type</label>
												</div>
												<Field
													component={RadioButton}
													type ="radio"
													value="percent"
													name="discount_type"
													className={`channel as-radio m-r-15 ${formValues.discount_type=="percent" ? "is-on":null}`}
													onChange={this.handleSelectDiscountType}
												>
													Discount Percentage
												</Field>
												<Field
													component={RadioButton}
													type ="radio"
													value="amount"
													name="discount_type"
													className={`channel as-radio m-r-15 ${formValues.discount_type=="amount" ? "is-on":null}`}
													onChange={this.handleSelectDiscountType}
												>
													Discount Amount
												</Field>
												<Field
													component={RadioButton}
													type ="radio"
													value="price"
													name="discount_type"
													className={`channel as-radio m-r-15 ${formValues.discount_type=="price" ? "is-on":null}`}
													onChange={this.handleSelectDiscountType}
												>
													Fixed Price
												</Field>
												<Field name="discount_type" component={this.renderError}/>
											</div>
											{
												formValues.discount_type=="percent" ? 
												<div>
													<Field
														name="discount_value"
														type="number"
														component={RenderInputPercent}
														label=""
														placeholder="0"
														id="discount_value"
													/>
												</div>
												:
												<div className="row">
													<div className='col-md-1'>
														<Field
															name="currency"
															component={RenderSelect}
															label=""
															placeholder=""
															id="currency"
															disableOptionText="Currency"
															dropdownOption={fromJS(dropdownCurrency)}
															onChange={this.handleSelectCurrency}
														/>
													</div>
													<div className='col-md-11'>
														<Field
															name="discount_value"
															type="number"
															component={RenderInputNumber}
															label=""
															placeholder="0"
															id="discount_value"
														/>
													</div>
												</div>
											}
											<div className="row">
												<div className="vertical-item--between col-md-6">
													<label>Valid From</label>
													<div className="datepick-container-new horizontal-item--between">
														<DatePicker
															onChange = {this.changeValidFrom}
															value={formValues.valid_from ? moment(formValues.valid_from).format("YYYY/MM/DD") : ''}
															dateFormat="YYYY/MM/DD"
															placeholderText="YYYY/MM/DD"
															minDate={moment()}
															maxDate={formValues.valid_to ? moment(formValues.valid_to).add(-1, 'day') : ''}
															/>
														<i className="icon-i-calendar" />
													</div>
													<Field name="valid_from" component={this.renderError}/>
												</div>
												<div className="vertical-item--between col-md-6">
													<label>Valid To</label>
													<div className="datepick-container-new horizontal-item--between">
														<DatePicker
															onChange= {this.changeValidTo}
															value={formValues.valid_to ? moment(formValues.valid_to).format("YYYY/MM/DD") : ''}
															dateFormat="YYYY/MM/DD"
															placeholderText="YYYY/MM/DD"
															minDate={formValues.valid_from ? moment(formValues.valid_from).add(1, 'day') : moment().add(1,'day')}
															/>
														<i className="icon-i-calendar" />
													</div>
													<Field name="valid_to" component={this.renderError}/>
												</div>
											</div>
											<div  className='m-t-10 m-b-5'>
												<Field
													name="max_user"
													type="number"
													component={RenderInputNumber}
													label="Maximum Redemption"
													placeholder="Maximum Redemption"
													id="max_user"
												/>
											</div>
											<div className="left m-t-36">
												{
													userPrivilege.includes('CREATE_USER_LEVEL') &&
													<button disabled={pristine || submitting || asyncValidating} className="submit-button">Save</button>
												}
											</div>
										</form>
									</div>
	              				</div>
	            			</div>
	          			</div>
	        		</section>
	      		</div>
			</div>
    	)
  	}
}

VoucherForm = reduxForm({
	form: 'VoucherForm',
	validate: validate,
		initialValues: {
			code: '',
			description: '',    
			platform: '',       
			discount_type: '',  
			currency: '',       
			discount_value: '', 
			valid_from: '',     
			valid_to: '',       
			max_user: '',       
			product: '',        
			product_values: '', 
			category: '',       
			category_values: '',
		},
	enableReinitialize: true
})(VoucherForm)

const selector = formValueSelector('VoucherForm')

const mapStateToProps = (state, ownProps) => {
	const voucher = state.voucher
	const initialValues = {
		code: voucher.get('code'),
		description: voucher.get('description'),    
		platform: voucher.get('platform'),       
		discount_type: voucher.get('discount_type'),  
		currency: voucher.get('currency'),       
		discount_value: voucher.get('discount_value'), 
		valid_from: voucher.get('valid_from'),     
		valid_to: voucher.get('valid_to'),       
		max_user: voucher.get('max_user') == 0 ? '': voucher.get('max_user'),       
		product: voucher.get('product'),        
		product_values: voucher.get('product_values'), 
		category: voucher.get('category'),       
		category_values: voucher.get('category_values'),
	}

	return {
		formValues: selector(
			state,
			'code',
			'description',
			'platform',
			'discount_type',
			'currency',
			'discount_value',
			'valid_from',
			'valid_to',
			'max_user',
			'product',
			'product_values',
			'category',
			'category_values'
		),
		initialValues,
		pathName: ownProps.location.pathname,
		globalIndicatorData: state.globalIndicatorData,
		voucherId: ownProps.match.params.voucherId,
		dropdownFeatures: state.dropdownFeatures,
		userPrivilege: state.userPrivilege,
		voucher: state.voucher,
		companiesDropdown: state.companiesDropdown,
		certificates: state.certificates,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		voucherSaveData: (voucherData) => dispatch(voucherSaveData(voucherData)),
		voucherCreateData: () => dispatch(voucherCreateData()),
		voucherUpdateData: (voucherId,voucherData) => dispatch(voucherUpdateData(voucherId,voucherData)),
		voucherGetDetail: (voucherId) => dispatch(voucherGetDetail(voucherId)),
		getDropdownFeatures: () => dispatch(getDropdownFeatures()),
		resetFormVoucher: () => dispatch(resetFormVoucher()),
		changeFieldValue: (field, value) => dispatch(change('VoucherForm', field, value, false, true)),
		getDropdownCompanies: (params) => dispatch(getDropdownCompanies(params)),
		voucherGetCertificates: (params) => dispatch(voucherGetCertificates(params)),
		validateVoucherCode: (code) => dispatch(validateVoucherCode(code)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherForm)
