import React, { Component } from 'react'
import $ from 'jquery'

class RenderInputCopy  extends Component {
	copy(value){
		$('.icon-i-copy').tooltip('destroy');
		$('.icon-i-copy').tooltip('hide').attr('data-original-title', "Copied").tooltip('fixTitle').tooltip('show');
		navigator.clipboard.writeText(value)
		setTimeout(function(){
			$('.icon-i-copy').tooltip('destroy');
			$('.icon-i-copy').tooltip('hide').attr('data-original-title', "Copy to Clipboard").tooltip('fixTitle');
		}, 500);	
	}

	render() {
		const { input, id, type, disabled, label, placeholder, desc, meta: { touched, error }, className, max, min, infoLabel} = this.props
		let maxLength = 255
		if(max != undefined){
			maxLength = max
		}

		let minLength = 0;
		if(min != undefined){
			minLength = min
		}
		return(
				<div className={`input-container ${touched && error && 'has-error'}`}>
					<div><label>{label}</label></div>
					{
						infoLabel && <div className='form-label-info'><i>{infoLabel}</i></div>
					}
					<div className='form-copy'>
						<input className={className} type={type} {...input} placeholder={placeholder} id={id} disabled={disabled} minLength={minLength} maxLength={maxLength}></input>
						<i className='icon-i-copy' onClick={() => this.copy(input.value)} data-toggle="tooltip" data-placement="top" title='Copy to Clipboard'></i>
						{touched && error && <div><span className="error">{error}</span></div>}
					</div>
				</div>
		)
	}
}


export default RenderInputCopy
